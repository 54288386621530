import axios from "axios";

const Default = {
    triggerEl: null,    // Element déclencheur du captcha
    preloaderEl: null,  // Element de preloading du captcha
    reloadButtonEl: null,  // Bouton pour recharger le captcha
    onShow: () => {},
    onHide: () => {},
}

export default class Captcha {

    /**
     * Constructeur du captcha
     * @param targetEl  Element recevant l'image du captcha
     * @param options   Options complémentaires
     */
    constructor(targetEl = null, options = {}) {
        this._targetEl = targetEl
        this._triggerEl = options.triggerEl || Default.triggerEl
        this._preloaderEl = options.preloaderEl || Default.preloaderEl
        this._reloadButtonEl = options.reloadButtonEl || Default.reloadButtonEl
        this._visible = false;
        this._options = { ...Default, ...options}
        this._init()
    }

    /**
     * Initialisation du captcha
     * @private
     */
    _init () {
        this.initCaptcha()

        this._reloadButtonEl.addEventListener('click', () => {
            this.hide()
            this.initCaptcha()
        })
    }

    /**
     * Affichage du captcha
     */
    show () {
        if(this._preloaderEl) {
            this._preloaderEl.classList.add('hidden')
        }
        this._targetEl.classList.remove('hidden')
        this._visible = true
        this._options.onShow(this) // callback function
    }

    /**
     * Masquage du captcha
     */
    hide () {
        this._targetEl.classList.add('hidden')
        if(this._preloaderEl) {
            this._preloaderEl.classList.remove('hidden')
        }
        this._visible = false
        this._options.onHide(this) // callback function
    }


    initCaptcha () {
        axios.get('/captcha/api/flat')
            .then(response => {

                const image = document.createElement('img')
                image.src = response.data.img

                const child = this._targetEl.querySelector('img')
                if(child) {
                    this._targetEl.replaceChild(image, child)
                } else {
                    this._targetEl.appendChild(image)
                }

                this.show();
            })
            .catch(err => console.log(err))
    }
}

window.Captcha = Captcha;

export function initCaptcha () {
    document
        .querySelectorAll('[data-captcha]')
        .forEach((triggerEl) => {
            const targetEl = triggerEl.querySelector('[data-captcha-image]')
            const preloaderEl = triggerEl.querySelector('[data-captcha-skeleton]')
            const reloadButtonEl = triggerEl.querySelector('[data-captcha-reload-button]')

            if(!targetEl) {
                return;
            }

            new Captcha(targetEl, {
                triggerEl,
                preloaderEl,
                reloadButtonEl
            })
        })
}
